import ROLES from "../config/roles";

export const PUBLIC = {
  BASE_PATH: "/",
  PAGES: {
    LANDING: "home",
  },
};

// AUTH
export const AUTH = {
  BASE_PATH: "/auth",
  PAGES: {
    LOGIN: "login",
    FORGOT_PASSWORD: "forgot-password",
    RESET_PASSWORD: "reset-password",
    STUDENT_REGISTRATION: "student-registration",
  },
};

//PRIVATE
export const PRIVATE = {
  BASE_PATH: "/:userType",
  ADMIN: {
    ADMIN_BASE_PATH: ROLES.ADMIN,
    SUPER_ADMIN_BASE_PATH: ROLES.SUPER_ADMIN,
    PAGES: {
      INDEX: "dashboard",
      DASHBOARD: "dashboard",
      TASKS:"tasks",
      AGENTS:"agents",
      USERS:"subscribers",
      USERS_SINGLE:"subscribers/single",
      PROCESS:'process',
      PROCESS_TYPE:"processType",
      COUNTRY:"country",
      BANNER:"banner",
      COMPANY:"company",
      NOTIFICATION:"notification",
      CHAT:"chat",
      SETTINGS: "settings",
      CHANGE_PASSWORD: "settings/change-password",
      RESET:'reset',
      NEW_PROCESS:'np',
      SINGLE_SUBPROCESS:'singleSubProcess',
      SINGLE_SUBSCRIBER_TASK:'subscribers/single-subscriber-tasks'
    },
  },
};

export const ERROR = {
  ERROR_403: "/403",
  CATCH_ALL: "*",
};
